.contact {
  min-height: 100vh;
  padding-top: 56px;
}

.contact__info {
  font-size: 1.5em;
  text-align: initial;
  margin: 5rem 0;
}

.contact__extra {
  font-size: 1.5em;
  text-align: initial;
  margin: 5rem 0;
}
