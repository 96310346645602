.navbar {
    background-color: transparent !important;
    position: absolute !important;
    width: 100% !important;
    z-index: 9999999999999999;
}

.navbar-toggler {
    border: none !important;
}

.nav-link {
    text-align: end !important;
}