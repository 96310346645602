.project__name {
    font-size: 4em;
    opacity: 1;
    width: fit-content;
    margin-bottom: 20px;

    padding: 0 10px;
    
}

.project__link {
    margin: 1rem 0;
    font-size: 1.2em;
    font-weight: bold;
}

.project__info {
    margin-top: 50vh;
    text-align: initial;
    width: 60%;
}


.project__description {
    margin-bottom: 20px;
    min-height: 100px;
    padding: 5px 10px;
    
}

.project__link {
    cursor: pointer;
    width: fit-content;
    padding-left: 10px;
}

.project__secondary-image {
    position: absolute;
    top: 30vh;
    left: 63vw;
    max-width: 30vw;
    max-height: 100vh;
    z-index: 1;
}

.slide {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 5%;
    text-align: initial;
   position: relative;
}

  h1 {
      font-size: 3.5em;
      margin-bottom: 1em;
  }

  h2 {
      margin-top: 0.25em;
      margin-bottom: 0.75em;
    font-size: 2.5em;
}

@media (max-width: 199px) {
    h1 { font-size: 1.2em !important; }
    h2 { font-size: 0.7em !important; }
    .project__name {font-size: 0.9em;}
  }
  
  @media (min-width: 200px) and  (max-width: 249px) {
    h1 { font-size: 1.4em !important; }
    h2 { font-size: 0.9em !important; }
    .project__name {font-size: 1.1em;}
    
  }
  
  @media (min-width: 250px) and  (max-width: 299px) {
    h1 { font-size: 1.6em !important; }
    h2 { font-size: 1.1em !important; }
    .project__name {font-size: 1.4em;}
  }
  
  @media (min-width: 300px) and  (max-width: 349px) {
    h1 { font-size: 1.8em !important; }
    h2 { font-size: 1.3em !important; }
    .project__name {font-size: 1.7em;}
  }
  
  @media (min-width: 350px) and  (max-width: 399px) {
    h1 { font-size: 2em !important; }
    h2 { font-size: 1.5em !important; }
    .project__name {font-size: 2em;}
  }

  @media (min-width: 400px) and  (max-width: 449px) {
    h1 { font-size: 2.2em !important; }
    h2 { font-size: 1.7em !important; }
    .project__name {font-size: 2.3em;}
  }
  
  @media (min-width: 450px) and  (max-width: 499px) {
    h1 { font-size: 2.4em !important; }
    h2 { font-size: 1.9em !important; }
    .project__name {font-size: 2.6em;}
  }


  @media  (max-width: 444px) {
    .slide__side-image {
      width: 100% !important;
      margin: 0 !important;
  }
}

  @media  (max-width: 1000px) {
  

    .project__info {
        width: 100%;
        margin-top: 20vh;
    }
    .project__secondary-image {
        display: none;
    }
  }

  .slide__image-set {
      width: 100%
  }

  .slide__col {
    max-width: 800px;
  }
.col, .slide__col  {
    min-width: 200px;
    padding: 0px !important;
}

h3 {
  margin-bottom: 3rem;
}

.slide__footnote {
  margin-top: 15px
}

  .slide__image-set__container {
    padding: 5% 0%;
    width: 100%;
    text-align: center;
  }

  .slide__image-set__image {
    padding: 0% 2%;
    max-height: 90vh;
    max-width: 100%;

}

.slide__image-set__caption {
    margin-top: 1em;
    margin: 1em;
}

.slide__side-image {
    width: 90%;
    padding: 5% 0;
    margin: 0 10%;
}

.slide__image-set__col {
  padding: 0 !important;
}

.slide__side-image--small {
  width: 60%;
  margin: 0 20%;
  
}

.slide__side-image--left {
  margin-left: 0%;
}

.slide__side-image--right {
  margin-right: 0%;
}


.slide__link {
    font-size: 2em;
    margin-top: 1.5rem;
}

.slide__quote-text {
    text-align: center;
}

.slide__quote-source {
    text-align: end;
    width: 100%;
}

.project-page__project-skill{
  background-color: #ebebeb;
  color: grey;
  padding: 5px !important;
  margin-right: 5px;
  margin-bottom: 10px;
  width: fit-content;
  display: inline-block;
}

.project-page__type, .project-page__time, .project-page__duration  {
  padding: 5px 0px !important;
  margin-right: 10px;
  margin-bottom: 5px;
  width: fit-content;
  display: inline-block;
}

.slide__image-set__caption {
  text-align: center;
}

.slide__video-container {
  text-align: center;
}

.slide__icon {
  position: absolute;
  top: 25px;
  right: 25px;
  font-size: 7em;
}

.slide__col {
  display: flex;
    justify-content: center;
    flex-direction: column;
}

.row {

  margin-right: 0px !important;
  margin-left: 0px !important;
}

.slide__list-item {
  margin: 10px 0;
}
