@import url(https://fonts.googleapis.com/css2?family=Lexend+Exa&family=Overpass&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Sometype+Mono:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
body {
  margin: 0;
  font-family: "Overpass", "Work Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fancy {
  font-family: "Lexend Exa", sans-serif !important;
}

.App {
  text-align: center;
  background-color: #f0f0eb;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.preload {
  display: none;
}

.navbar {
    background-color: transparent !important;
}

.projects__image {
    width: 100%;
}

.card-deck {
    padding: 0 !important;
    width: 100% !important;
}

.projects__card {
    background-color: transparent !important;
    border: none;
    margin: 17px !important;
 }


 .projects__card:hover {
   text-decoration: none;
    opacity: 0.7;
 }

 .projects__project-title {
    text-align: left !important;
    text-align: initial !important;
    color: black !important;
    margin-bottom: .35rem !important;
 }

 .projects__project-type {
    text-align: left !important;
    text-align: initial !important;
    color: grey !important;
    margin-bottom: 0px !important;
 }

 .projects__project-time {
    text-align: left !important;
    text-align: initial !important;
    color: grey !important;
    font-size: 0.8em !important;
 }

.projects__card  > .card-body {
    padding: 0.75rem 0 !important;
}

.projects__card  > .card-footer {
    background-color: transparent !important;
    border: none !important;
    text-align: left !important;
    text-align: initial !important;
    padding: 0px !important;
}

.projects__project-skill{
    background-color: #ebebeb;
    color: grey;
    padding: 5px !important;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 0.8em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: inline-block;
}

.placeholder {
    opacity: 0;
}

.projects {
    padding-top: 56px;
}

.intro {
  font-size: 2em;
  text-align: left;
  text-align: initial;
  margin: 5rem 0;
}

.intro__extra {
  font-size: 1.5em;
  text-align: left;
  text-align: initial;
  margin: 0;
}

.home__project-header {
  font-size: 2em;
  text-align: left;
  text-align: initial;
  margin-top: 5rem;
  margin-bottom: 2rem;
}

.extra {
  padding-top: 16px;
}

@media (max-width: 199px) {
  .intro,
  .home__project-header {
    font-size: 0.5em;
    margin: 2rem 0;
  }
  .intro__extra {
    font-size: 0.4em;
  }
}

@media (min-width: 200px) and (max-width: 249px) {
  .intro,
  .home__project-header {
    font-size: 0.75em;
    margin: 2.5rem 0;
  }
  .intro__extra {
    font-size: 0.6em;
  }
}

@media (min-width: 250px) and (max-width: 299px) {
  .intro,
  .home__project-header {
    font-size: 1em;
    margin: 3rem 0;
  }
  .intro__extra {
    font-size: 0.7em;
  }
}

@media (min-width: 300px) and (max-width: 349px) {
  .intro,
  .home__project-header {
    font-size: 1.25em;
    margin: 3.5rem 0;
  }
  .intro__extra {
    font-size: 0.9em;
  }
}

@media (min-width: 350px) and (max-width: 399px) {
  .intro,
  .home__project-header {
    font-size: 1.5em;
    margin: 4rem 0;
  }
  .intro__extra {
    font-size: 1.1em;
  }
  /* * { font-size: 96%} */
}

@media (min-width: 400px) and (max-width: 449px) {
  .intro,
  .home__project-header {
    font-size: 1.75em;
    margin: 4.5rem 0;
  }
  .intro__extra {
    font-size: 1.3em;
  }
}

.project__name {
    font-size: 4em;
    opacity: 1;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 20px;

    padding: 0 10px;
    
}

.project__link {
    margin: 1rem 0;
    font-size: 1.2em;
    font-weight: bold;
}

.project__info {
    margin-top: 50vh;
    text-align: left;
    text-align: initial;
    width: 60%;
}


.project__description {
    margin-bottom: 20px;
    min-height: 100px;
    padding: 5px 10px;
    
}

.project__link {
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 10px;
}

.project__secondary-image {
    position: absolute;
    top: 30vh;
    left: 63vw;
    max-width: 30vw;
    max-height: 100vh;
    z-index: 1;
}

.slide {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 5%;
    text-align: left;
    text-align: initial;
   position: relative;
}

  h1 {
      font-size: 3.5em;
      margin-bottom: 1em;
  }

  h2 {
      margin-top: 0.25em;
      margin-bottom: 0.75em;
    font-size: 2.5em;
}

@media (max-width: 199px) {
    h1 { font-size: 1.2em !important; }
    h2 { font-size: 0.7em !important; }
    .project__name {font-size: 0.9em;}
  }
  
  @media (min-width: 200px) and  (max-width: 249px) {
    h1 { font-size: 1.4em !important; }
    h2 { font-size: 0.9em !important; }
    .project__name {font-size: 1.1em;}
    
  }
  
  @media (min-width: 250px) and  (max-width: 299px) {
    h1 { font-size: 1.6em !important; }
    h2 { font-size: 1.1em !important; }
    .project__name {font-size: 1.4em;}
  }
  
  @media (min-width: 300px) and  (max-width: 349px) {
    h1 { font-size: 1.8em !important; }
    h2 { font-size: 1.3em !important; }
    .project__name {font-size: 1.7em;}
  }
  
  @media (min-width: 350px) and  (max-width: 399px) {
    h1 { font-size: 2em !important; }
    h2 { font-size: 1.5em !important; }
    .project__name {font-size: 2em;}
  }

  @media (min-width: 400px) and  (max-width: 449px) {
    h1 { font-size: 2.2em !important; }
    h2 { font-size: 1.7em !important; }
    .project__name {font-size: 2.3em;}
  }
  
  @media (min-width: 450px) and  (max-width: 499px) {
    h1 { font-size: 2.4em !important; }
    h2 { font-size: 1.9em !important; }
    .project__name {font-size: 2.6em;}
  }


  @media  (max-width: 444px) {
    .slide__side-image {
      width: 100% !important;
      margin: 0 !important;
  }
}

  @media  (max-width: 1000px) {
  

    .project__info {
        width: 100%;
        margin-top: 20vh;
    }
    .project__secondary-image {
        display: none;
    }
  }

  .slide__image-set {
      width: 100%
  }

  .slide__col {
    max-width: 800px;
  }
.col, .slide__col  {
    min-width: 200px;
    padding: 0px !important;
}

h3 {
  margin-bottom: 3rem;
}

.slide__footnote {
  margin-top: 15px
}

  .slide__image-set__container {
    padding: 5% 0%;
    width: 100%;
    text-align: center;
  }

  .slide__image-set__image {
    padding: 0% 2%;
    max-height: 90vh;
    max-width: 100%;

}

.slide__image-set__caption {
    margin-top: 1em;
    margin: 1em;
}

.slide__side-image {
    width: 90%;
    padding: 5% 0;
    margin: 0 10%;
}

.slide__image-set__col {
  padding: 0 !important;
}

.slide__side-image--small {
  width: 60%;
  margin: 0 20%;
  
}

.slide__side-image--left {
  margin-left: 0%;
}

.slide__side-image--right {
  margin-right: 0%;
}


.slide__link {
    font-size: 2em;
    margin-top: 1.5rem;
}

.slide__quote-text {
    text-align: center;
}

.slide__quote-source {
    text-align: end;
    width: 100%;
}

.project-page__project-skill{
  background-color: #ebebeb;
  color: grey;
  padding: 5px !important;
  margin-right: 5px;
  margin-bottom: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: inline-block;
}

.project-page__type, .project-page__time, .project-page__duration  {
  padding: 5px 0px !important;
  margin-right: 10px;
  margin-bottom: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: inline-block;
}

.slide__image-set__caption {
  text-align: center;
}

.slide__video-container {
  text-align: center;
}

.slide__icon {
  position: absolute;
  top: 25px;
  right: 25px;
  font-size: 7em;
}

.slide__col {
  display: flex;
    justify-content: center;
    flex-direction: column;
}

.row {

  margin-right: 0px !important;
  margin-left: 0px !important;
}

.slide__list-item {
  margin: 10px 0;
}

.navbar {
    background-color: transparent !important;
    position: absolute !important;
    width: 100% !important;
    z-index: 9999999999999999;
}

.navbar-toggler {
    border: none !important;
}

.nav-link {
    text-align: end !important;
}
.contact {
  min-height: 100vh;
  padding-top: 56px;
}

.contact__info {
  font-size: 1.5em;
  text-align: left;
  text-align: initial;
  margin: 5rem 0;
}

.contact__extra {
  font-size: 1.5em;
  text-align: left;
  text-align: initial;
  margin: 5rem 0;
}

.smoothie-maker {
  min-height: 100vh;
  padding-top: 56px;
  font-family: "Sometype Mono", "Overpass", "Work Sans", sans-serif !important;
}

.smoothie-maker-title {
  font-weight: 600;
  font-size: 3rem;
  margin-bottom: 32px;
  margin-top: 32px;
}

.smoothie-maker-subtitle {
  font-weight: 500;
  font-size: 1.5rem;
  margin-top: 48px;
  margin-bottom: 32px;
}

.smoothie-maker-description {
  color: grey;
  font-weight: 400;
  font-size: 1rem;
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 16px;
}

.smoothie-maker-subdescription {
  color: grey;
  font-weight: 400;
  font-size: 1rem;
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 16px;
}

a.smoothie-maker-subdescription:hover {
  color: grey;
}

.smoothie-maker-image {
  max-width: 100%;
  max-height: 90vh;
  width: auto;
  height: auto;
}

.smoothie-maker-footer {
  padding-top: 8px;
  color: grey;
}

